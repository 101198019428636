.form_container {
  margin: 10px;
  margin-left: 30px;
}
.form_controller {
  font-size: 14px;
  background: #f2f3f3;
  border-radius: 7px;
  padding: 10px;
  font-weight: 600;
}

.form_label {
  width: auto;
  font-weight: 500;
  font-size: 11px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 2px;

  color: #474747;
}


.form_label_yes_no {
  width: auto;
  font-weight: 500;
  font-size: 11px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.1em;
  

  color: #474747;
}

.form_select {
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 14px;
}

.form_radio_active {
  width: 26px;
  height: 26px;
  border: 1px solid #007ac3;
  background: #007ac3;
  border-radius: 50%;
 
}

.form_radio_default {
  width: 26px;
  height: 26px;
  border: 1px solid #007ac3;
  background: #ffffff;
  border-radius: 50%;
 
}

.form_radio_label{
  font-weight: 500;
font-size: 14px;
margin-left: 10px;

/* identical to box height */


color: #000000;
}

.form_header_main {
  top: 2.82%;
  bottom: 95.96%;

  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
  text-transform: uppercase;

  color: #000000;
}

.form_header_inner {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;

  color: #000000;
}

.form_header_label {
  font-style: italic;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: justify;

  color: #000000;
}

.form_hr_line {
  width: 100%;
  height: 0px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;

  border: 1px solid #dfdede;
}
